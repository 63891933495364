@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@layer utilities {
  .custom-utility {
    @apply bg-red-500;
  }
}

.scroll-container::-webkit-scrollbar {
  display: none;
}
@layer components {
  .h1 {
    @apply font-sans font-semibold text-[26px] text-tertiary;
  }
  .p1 {
    @apply font-sans text-[15px] text-textSecondary font-normal;
  }
  .h2 {
    @apply font-sans font-medium text-[22px] text-tertiary;
  }
  .h1-sm {
    @apply font-sans font-semibold text-[20px] text-tertiary;
  }
  .p1-sm {
    @apply font-sans text-[14px] text-textSecondary font-normal;
  }
  .h2-sm {
    @apply font-sans font-medium text-[18px] text-tertiary;
  }

  .h3 {
    @apply font-sans font-medium text-[16px] text-tertiary;
  }
}
